<template>
  <div class="card">
    <h3 class="card-title">Ver noticia</h3>
    <template v-if="noticia">
      <div class="cols2">
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-success"
          v-model="noticia.titulo"
          label="Título"
          id="titulo"
          placeholder="Sin datos"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-secondary"
          v-model="dejar_fijo"
          label="Dejar fijo"
          id="fijo"
          placeholder="Sin datos"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-secondary"
          v-model="desde_formatted"
          label="Desde"
          id="desde"
          placeholder="Sin datos"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-secondary"
          v-model="hasta_formatted"
          label="Hasta"
          id="hasta"
          placeholder="Sin datos"
        ></pd-input>
      </div>
      <h4>Cartelera</h4>
      <hr />
      <div class="noticias">
        <div class="card" :class="classNoticia">
          <h4 class="card-title">{{ noticia.titulo }}</h4>
        </div>
      </div>
      <h4 class="mt-2">Noticia completa</h4>
      <hr />
      <div>
        <h2 class="card-title text-center mt-2 mb-2">{{ noticia.titulo }}</h2>
        <div
          class="recibo-preview"
          v-if="noticia.archivo_url"
        >
            <div class="commands-pdf">
                <div class="zoom-command">
                  <button type="button" id="zoom-minus" @click="width -= 50">
                    <icon feather="minus"/>
                  </button>
                  <b style="margin: auto 5px">ZOOM</b>
                  <button type="button" id="zoom-plus" @click="width += 50">
                    <icon feather="plus"/>
                  </button>
                </div>
                <button type="button" class="print-command" id="print-button" @click="imprimir">
                  <b style="margin: auto 5px">IMPRIMIR</b>
                  <icon feather="printer" />
                </button>
            </div>
          <div class="pdf-viewer">
            <pdf
              style="margin: 10px auto"
              v-for="i in numPages"
              :key="i"
              ref="pdf"
              :src="noticia.archivo_url"
              :rotate="rotate"
              :page="i"
              :style="'width:calc(70% + ' + width + 'px );'"
            ></pdf>
          </div>
        </div>
        <div style="padding: 0 5px" v-html="noticia.contenido" v-else></div>
      </div>
      <div class="d-flex space-between mt-1">
        <button class="btn btn-warning" @click="editNoticia">
          Editar noticia
        </button>
        <button class="btn btn-error" @click="eliminarNoticia">
          Eliminar noticia
        </button>
      </div>
    </template>
  </div>
</template>
<script>
import { NoticiasServices } from "../services/NoticiasServices";
import Swal from "sweetalert2";
import pdf from "vue-pdf";
export default {
  name: "VerNoticia",
  components: {
    pdf
  },
  data() {
    return {
      loading: false,
      noticia: null,
      pdfNoticia: null,
      loadedRatio: 0,
      page: 1,
      width: 0,
      numPages: 0,
      rotate: 0,
    };
  },
  computed: {
    classNoticia() {
      let clase = "";
      if (this.noticia.tipo) {
        clase = `card-${this.noticia.tipo}`;
      }
      return clase;
    },
    dejar_fijo() {
      if (this.noticia.fijo === 1) {
        return "SI";
      }
      return "NO";
    },
    desde_formatted() {
      return NoticiasServices.normalDate(this.noticia.desde);
    },
    hasta_formatted() {
      return NoticiasServices.normalDate(this.noticia.hasta);
    },
  },
  mounted() {
    const noticiaId = this.$route.params.id;
    this.loading = true;
    NoticiasServices.api
      .find(noticiaId)
      .then((response) => {
        this.noticia = response.noticia;
      if (this.noticia.archivo_url) {
        this.pdfNoticia = pdf.createLoadingTask(
            this.noticia.archivo_url
        );
        this.pdfNoticia.promise.then((loadedPDF) => {
          this.numPages = loadedPDF.numPages;
        });
      }
      })
      .catch((error) => {
        Swal.fire("Error", `${error}`, "error");
      })
      .finally(() => (this.loading = false));
  },
  methods: {
    editNoticia() {
      this.$router.push({
        name: "EditarNoticia",
        params: { id: this.noticia.id },
      });
    },
    eliminarNoticia() {
      Swal.fire({
        title: "Confirme",
        html: `<p>Está seguro que desea eliminar <b>${this.noticia.titulo}</b>?</p>`,
        icon: "question",
        showConfirmButton: true,
        confirmButtonText: "Eliminar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          NoticiasServices.api.delete(this.noticia).then(() => {
            Swal.fire("Éxito", "<p>Se ha eliminado con éxito</p>", "success");
            this.$router.push({ name: "ListarNoticias" });
          });
        }
      });
    },
    async imprimir(){
      const base64Response = await fetch(this.noticia.archivo_url);
      const blob = await base64Response.blob();
      const url = window.URL.createObjectURL(blob);
      const fileLink = document.createElement("a");
      fileLink.href = url;
      fileLink.target = "_blank";
      fileLink.style.display = "none";
      fileLink.click();
    }
  },
};
</script>
